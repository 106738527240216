<template>
  <b-collapse id="filter-box">
    <v-card flat class="pa-3">
      <v-container fluid>
        <v-row>
          <v-col cols="12" lg="6" class="mb-5">
            <v-text-field
              label="Search by Name, ID, or Phone"
              outlined
              dense
              hide-details
              v-model.trim="filters.search"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6" class="mb-5">
            <v-select
              :items="roles"
              label="Role"
              item-text="name"
              item-value="id"
              outlined
              dense
              hide-details
              v-model="filters.role"
            ></v-select>
          </v-col>
          <v-col cols="12" lg="6" class="mb-5">
            <v-select
              :items="callStatusOptions"
              label="Call Status"
              item-text="text"
              item-value="value"
              outlined
              dense
              hide-details
              v-model="filters.call_status"
            ></v-select>
          </v-col>
          <v-col cols="12" lg="6" class="mb-5">
            <v-select
              :items="callTypeOptions"
              label="Call type"
              item-text="text"
              item-value="value"
              outlined
              dense
              hide-details
              v-model="filters.call_type"
            ></v-select>
          </v-col>
          <v-col cols="12" lg="6" class="mb-5">
            <v-select
              :items="outputStatusOptions"
              label="Output Status"
              item-text="text"
              item-value="value"
              outlined
              dense
              hide-details
              v-model="filters.output_status"
            ></v-select>
          </v-col>
          <v-col cols="12" lg="6" class="mb-5">
            <v-select
              :items="botOptions"
              label="Prompt"
              item-text="text"
              item-value="value"
              outlined
              dense
              hide-details
              v-model="filters.bot"
            ></v-select>
          </v-col>
          <div class="col-6 col-lg-6 mb-5">
            <button
              class="btn btn-info font-weight-bolder font-size-h6 px-5 col"
              @click="applyFilter"
            >
              Apply
            </button>
          </div>
          <div class="col-6 col-lg-6 mb-5">
            <button
              class="btn btn-secondary font-weight-bolder font-size-h6 px-5 col"
              @click="resetQuery"
            >
              Reset
            </button>
          </div>
        </v-row>
      </v-container>
    </v-card>
  </b-collapse>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
const initQuery = {
  search: "",
  role: null,
  call_status: null,
  output_status: null,
  call_type: null,
  bot: null,
};

export default {
  name: "AICallResultsFilter",
  data() {
    return {
      filters: { ...initQuery },
      roleOptions: [
        { value: null, text: "All Roles" },
        { value: "Developer", text: "Developer" },
        { value: "Designer", text: "Designer" },
        // Add more roles as needed
      ],
      callStatusOptions: [
        { value: "scheduled", text: "Scheduled" },
        { value: "initiated", text: "Initiated" },
      ],
      outputStatusOptions: [
        { value: "answered", text: "Answered" },
        { value: "no_answer", text: "No Answer" },
        { value: "unreachable", text: "Unreachable" },
      ],
      callTypeOptions: [
        { value: "main", text: "Main" },
        { value: "follow_up", text: "Follow up" },
      ],
      botOptions: [
        { value: "lama", text: "Lama" },
        { value: "ghada", text: "Ghada" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      roles: "talents/getRoles",
    }),
  },
  methods: {
    ...mapActions({
      getRoles: "talents/getRoles",
    }),
    applyFilter() {
      const { search, role, call_status, output_status, call_type, bot } =
        this.filters;

      // Check if at least one filter is applied
      if (
        !search &&
        !role &&
        !call_status &&
        !output_status &&
        !call_type &&
        !bot
      ) {
        this.$toasted.show("Please Add At Least One Filter", {
          type: "error",
          icon: "error",
        });
        return;
      }

      // Prepare data object with applied filters
      let data = {};
      if (search) data.search = search;
      if (role) data.role = role;
      if (call_status) data.call_status = call_status;
      if (output_status) data.output_status = output_status;
      if (call_type) data.call_type = call_type;
      if (bot) data.bot = bot;
      // Emit the applyFilter event with the data
      this.$emit("applyFilter", data);
    },
    resetQuery() {
      this.filters = { ...initQuery };
      this.$emit("resetQuery");
    },
  },
  created() {
    this.getRoles();
  },
};
</script>

<style scoped>
.v-card {
  margin-bottom: 20px;
  background-color: #ffffff;
  border-radius: 5px;
}
.v-card-title {
  font-weight: bold;
}
</style>
