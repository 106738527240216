<template>
  <v-app id="ai-call-results" class="ai-call-results">
    <!--begin::Dashboard-->
    <div class="card card-custom card-stretch h-auto px-5 py-5">
      <v-row class="mb-4">
        <v-col cols="12" md="3">
          <v-card
            hover
            class="rounded-lg elevation-3 transition-swing card-stretch"
          >
            <v-card-title class="primary--text d-flex justify-space-between">
              <div class="d-flex align-center">
                <v-icon left color="primary" size="32"
                  >mdi-account-multiple</v-icon
                >
                <span class="font-weight-bold break-word"
                  >Total Candidates Contacted</span
                >
              </div>
            </v-card-title>
            <v-card-text
              class="text-h3 text-center py-6 font-weight-bold primary--text"
            >
              {{ aiStatistics?.total_candidates }}
              <!-- <div class="text-caption grey--text">candidates</div> -->
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card hover class="rounded-lg elevation-3 transition-swing h-100">
            <v-card-title class="success--text d-flex justify-space-between">
              <div class="d-flex align-center">
                <v-icon left color="success" size="32"
                  >mdi-calendar-check</v-icon
                >
                <span class="font-weight-bold break-word"
                  >Total Candidates unavailable</span
                >
              </div>
            </v-card-title>
            <v-card-text
              class="text-h3 text-center py-6 font-weight-bold success--text"
            >
              {{ aiStatistics?.total_unavailable }}
              <!-- <div class="text-caption grey--text">updated</div> -->
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card hover class="rounded-lg elevation-3 transition-swing h-100">
            <v-card-title class="error--text d-flex justify-space-between">
              <div class="d-flex align-center">
                <v-icon left color="error" size="32">mdi-account-off</v-icon>
                <span class="font-weight-bold break-word"
                  >Total Candidates Updated</span
                >
              </div>
            </v-card-title>
            <v-card-text
              class="text-h3 text-center py-6 font-weight-bold error--text"
            >
              {{ aiStatistics?.total_answered }}
              <!-- <div class="text-caption grey--text">of candidates</div> -->
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <v-card hover class="rounded-lg elevation-3 transition-swing h-100">
            <v-card-title class="warning--text d-flex justify-space-between">
              <div class="d-flex align-center">
                <v-icon left color="warning" size="32">mdi-clock-alert</v-icon>
                <span class="font-weight-bold break-word"
                  >Total Number of Calls</span
                >
              </div>
            </v-card-title>
            <v-card-text
              class="text-h3 text-center py-6 font-weight-bold warning--text"
            >
              {{ aiStatistics?.total_calls }}
              <!-- <div class="text-caption grey--text">pending</div> -->
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div class="card card-custom card-stretch h-auto">
      <!--begin::Header-->
      <div class="card-header border-0 py-5 align-items-center">
        <h3 class="card-title align-items-start flex-column m-0 py-3">
          <span class="card-label font-weight-bolder text-dark">
            AI Agent Call Results
          </span>
        </h3>
        <button
          class="btn btn-light font-weight-bolder text-dark-75"
          v-b-toggle.filter-box
        >
          <v-icon>mdi-tune</v-icon> Filter
        </button>
      </div>
      <!--end::Header-->
      <!--begin::Body-->

      <AICallResultsFilter
        @applyFilter="applyFilter"
        @resetQuery="resetQuery"
      />

      <div class="card-body pt-0 pb-3">
        <div class="tab-content">
          <!--begin::Table-->
          <div
            class="table-responsive mb-0"
            v-if="aiCallResults && aiCallResults.length > 0"
          >
            <table
              class="table table-head-custom table-vertical-center table-head-bg table-borderless"
            >
              <thead>
                <tr class="text-left">
                  <th style="width: 150px">Talent Name</th>
                  <th style="width: 150px">Talent Role</th>
                  <th style="width: 150px">Email</th>
                  <th style="width: 160px">Phone Number</th>
                  <th style="width: 130px">Output Status</th>
                  <th style="width: 130px">Call Status</th>
                  <th style="width: 130px">Prompt</th>
                  <th style="width: 200px">Scheduled at</th>
                  <th style="width: 130px">Call Type</th>
                  <th style="width: 140px">Deadline Countdown</th>
                  <th style="width: 100px">Final Status</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="call in aiCallResults"
                  :key="`${call.callable.email}-${call.callable.name}-${call.phone}`"
                >
                  <td>
                    <span
                      class="text-dark-75 font-weight-bolder font-size-lg text-clamp-2"
                    >
                      {{ call.callable.name }}
                    </span>
                  </td>
                  <td>
                    <span
                      class="text-dark-75 font-weight-bolder font-size-lg text-clamp-2"
                    >
                      {{
                        call.type === "main"
                          ? "Main"
                          : call.type === "followup"
                          ? "Followup"
                          : "N/A"
                      }}
                    </span>
                  </td>
                  <td>
                    <span class="text-dark-75 font-weight-bolder font-size-lg">
                      {{ call.callable.email }}
                    </span>
                  </td>
                  <td>
                    <span class="text-dark-75 font-weight-bolder font-size-lg">
                      {{ call.phone }}
                    </span>
                  </td>
                  <td>
                    <b-badge
                      :variant="getOutputStatusColor(call.output_status)"
                      pill
                    >
                      {{ call.output_status || "N/A" }}
                    </b-badge>
                  </td>
                  <td>
                    <span
                      class="text-dark-75 font-weight-bolder font-size-lg text-clamp-2"
                    >
                      {{ call.call_status }}
                    </span>
                  </td>
                  <td>
                    <span
                      class="text-dark-75 font-weight-bolder font-size-lg text-clamp-2"
                    >
                      {{ call.prompt || "N/A" }}
                    </span>
                  </td>
                  <td>
                    <span class="text-dark-75 font-weight-bolder font-size-lg">
                      {{ formatDeadlineDate(call.scheduled_at) }}
                    </span>
                  </td>
                  <td>
                    <span class="text-dark-75 font-weight-bolder font-size-lg">
                      {{
                        call.type === "initial"
                          ? "Initial"
                          : call.type === "followup"
                          ? "Followup"
                          : "N/A"
                      }}
                    </span>
                  </td>
                  <td>
                    <span class="text-dark-75 font-weight-bolder font-size-lg">
                      {{ getDeadlineCountdownDisplay(call.expires_at) }}
                    </span>
                  </td>
                  <td>
                    <span class="text-dark-75 font-weight-bolder font-size-lg">
                      {{ getSourceStatusDisplay(call.source_status) }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h5
            class="my-5 text-muted text-center"
            v-if="!aiCallResults || aiCallResults.length === 0"
          >
            No Results Found
          </h5>
          <!--end::Table-->
        </div>
      </div>
      <!--end::Body-->
    </div>
    <!--end::Dashboard-->

    <!-- Call Details Modal -->
    <b-modal id="call-details-modal" title="Call Details">
      <div v-if="selectedCall" class="call-details">
        <!-- Call Status Overview Card -->
        <v-card flat class="mb-4">
          <v-card-title class="subtitle-1 font-weight-bold d-flex align-center">
            <v-icon left color="primary">mdi-phone</v-icon>
            Call Status Overview
          </v-card-title>
          <v-card-text>
            <b-badge
              :variant="getCallStatusColor(selectedCall.callStatus)"
              pill
              class="mr-2"
            >
              {{ selectedCall.callStatus }}
            </b-badge>
            <b-badge
              :variant="getCompletionColor(selectedCall.profileCompletion)"
              pill
              class="mr-2"
            >
              {{ selectedCall.profileCompletion }}% Complete
            </b-badge>
            <b-badge
              :variant="getFollowUpColor(selectedCall.followUpStatus)"
              pill
            >
              {{ selectedCall.followUpStatus }}
            </b-badge>
          </v-card-text>
        </v-card>

        <!-- Call History Timeline -->
        <v-card flat class="mb-4">
          <v-card-title class="subtitle-1 font-weight-bold d-flex align-center">
            <v-icon left color="primary">mdi-history</v-icon>
            Call History
          </v-card-title>
          <v-card-text>
            <v-timeline dense>
              <v-timeline-item
                v-for="attempt in selectedCall.attempts"
                :key="attempt.id"
                :color="getStatusColor(attempt.status)"
                small
              >
                <template v-slot:icon>
                  <v-icon :color="getStatusColor(attempt.status)">
                    {{ getStatusIcon(attempt.status) }}
                  </v-icon>
                </template>
                <div class="d-flex justify-space-between align-center">
                  <div>
                    <span class="font-weight-medium">{{ attempt.status }}</span>
                    <div class="text-caption">{{ attempt.timestamp }}</div>
                  </div>
                </div>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </v-card>

        <!-- Response Details -->
        <v-card flat>
          <v-card-title class="subtitle-1 font-weight-bold d-flex align-center">
            <v-icon left color="primary">mdi-comment-text</v-icon>
            Response Details
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="4">
                <v-card outlined>
                  <v-card-text>
                    <div class="d-flex align-center mb-2">
                      <v-icon color="primary" class="mr-2"
                        >mdi-message-text</v-icon
                      >
                      <div class="font-weight-medium">Talent Response</div>
                    </div>
                    <div class="text-body-2">{{ selectedCall.responses }}</div>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" md="4">
                <v-card outlined>
                  <v-card-text>
                    <div class="d-flex align-center mb-2">
                      <v-icon
                        :color="selectedCall.voicemail ? 'success' : 'error'"
                        class="mr-2"
                      >
                        {{
                          selectedCall.voicemail
                            ? "mdi-voicemail"
                            : "mdi-voicemail-off"
                        }}
                      </v-icon>
                      <div class="font-weight-medium">Voicemail Status</div>
                    </div>
                    <div class="text-body-2">
                      {{
                        selectedCall.voicemail
                          ? "Voicemail Left"
                          : "No Voicemail"
                      }}
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="12" md="4">
                <v-card outlined>
                  <v-card-text>
                    <div class="d-flex align-center mb-2">
                      <v-icon
                        :color="
                          selectedCall.followUpPlanned ? 'success' : 'grey'
                        "
                        class="mr-2"
                      >
                        mdi-calendar-check
                      </v-icon>
                      <div class="font-weight-medium">Follow-up</div>
                    </div>
                    <div class="text-body-2">
                      {{
                        selectedCall.followUpPlanned
                          ? "Follow-up Scheduled"
                          : "No Follow-up Planned"
                      }}
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
    </b-modal>

    <!-- Pagination Component -->
    <div class="pagination-container">
      <p
        v-if="aiCallResults && aiCallResults.length > 0"
        class="pagination-container__desc"
      >
        {{
          $t("showing_results", {
            length: aiCallResults.length,
            total: aiCallResultsMeta.total,
          })
        }}
      </p>
      <b-pagination
        v-if="aiCallResultsMeta && aiCallResultsMeta.last_page > 1"
        v-model="page"
        :total-rows="aiCallResultsMeta.total"
        :per-page="perPage"
        align="center"
        last-number
        first-number
      ></b-pagination>
    </div>
  </v-app>
</template>

<script>
import AICallResultsFilter from "@/components/ai-call-results/AICallResultsFilter.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AICallResults",
  components: {
    AICallResultsFilter,
  },
  data() {
    return {
      filteredCalls: [],
      selectedCall: null,
      page: 1,
      perPage: 10,
      filters: {},
    };
  },
  computed: {
    ...mapGetters({
      aiCallResults: "aiCallResults/getAICallResults",
      aiCallResultsMeta: "aiCallResults/getAICallResultsMeta",
      aiStatistics: "aiCallResults/getAIStatistics",
    }),
    aiCallResultsQuery() {
      return {
        page: this.page,
        perPage: this.perPage,
      };
    },
  },
  watch: {
    page() {
      this.fetchAICallResults({ ...this.filters, ...this.aiCallResultsQuery });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "AI Call Results" },
    ]);
    this.fetchAICallResults();
    this.fetchAIStatistics();
  },
  methods: {
    ...mapActions({
      fetchAICallResults: "aiCallResults/fetchAICallResults",
      fetchAIStatistics: "aiCallResults/fetchAIStatistics",
    }),
    showCallDetails(call) {
      this.selectedCall = call;
      this.$bvModal.show("call-details-modal");
    },
    applyFilter(data) {
      this.filters = { ...data };
      this.fetchAICallResults({ ...data, ...this.aiCallResultsQuery });
    },
    resetQuery() {
      this.fetchAICallResults();
    },
    getStatusColor(status) {
      switch (status) {
        case "Answered":
          return "success";
        case "No Answer":
          return "warning";
        case "Voicemail":
          return "info";
        case "Busy":
          return "error";
        default:
          return "grey";
      }
    },
    getStatusIcon(status) {
      switch (status) {
        case "Answered":
          return "mdi-phone-in-talk";
        case "No Answer":
          return "mdi-phone-missed";
        case "Voicemail":
          return "mdi-voicemail";
        case "Busy":
          return "mdi-phone-off";
        default:
          return "mdi-help-circle";
      }
    },
    getCallStatusColor(callStatus) {
      switch (callStatus) {
        case "Answered":
          return "success";
        case "No Answer":
          return "warning";
        case "Voicemail":
          return "primary";
        case "Busy":
          return "danger";
        default:
          return "secondary";
      }
    },
    getCompletionColor(profileCompletion) {
      return profileCompletion >= 80 ? "success" : "danger";
    },
    getFollowUpColor(followUpStatus) {
      return followUpStatus === "Scheduled" ? "primary" : "secondary";
    },
    formatDeadline(deadline) {
      // Assuming deadline is a string like '3 days', '1 day', etc.
      if (!deadline) return "N/A";
      const [value, unit] = deadline.split(" ");
      return `${value} ${unit}${value > 1 ? "s" : ""}`;
    },
    getSourceStatusDisplay(sourceStatus) {
      switch (sourceStatus) {
        case "client_pending":
          return "Client Pending";
        case "talent_pending_update":
          return "Talent Pending Update";
        case "operation_pending":
          return "Operation Pending";
        case "talent_rejected":
          return "Talent Rejected";
        case "client_rejected":
          return "Client Rejected";
        case "talent_pending":
          return "Talent Pending";
        case "completed":
          return "Completed";
        case "renewed":
          return "Renewed";
        default:
          return "Unknown Status";
      }
    },
    getProfileCompletionDisplay(profileCompletion) {
      return profileCompletion ? `${profileCompletion}%` : "N/A";
    },
    formatDeadlineDate(date) {
      if (!date) return "N/A";
      return new Date(date).toLocaleString();
    },
    getDeadlineCountdownDisplay(deadline) {
      if (!deadline) return "N/A";
      const deadlineDate = new Date(deadline);
      const now = new Date();
      const diffInMs = deadlineDate - now;
      const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
      return `${diffInHours} hour${diffInHours !== 1 ? "s" : ""} remaining`;
    },
    getOutputStatusColor(status) {
      switch (status) {
        case "Answered":
          return "success";
        case "No Answer":
          return "danger";
        case "Voicemail":
          return "info";
        case "Busy":
          return "warning";
        default:
          return "secondary";
      }
    },
  },
};
</script>

<style>
.table-responsive {
  overflow-x: auto;
}
.break-word {
  word-break: break-word;
  overflow-wrap: break-word;
}
</style>
